import logo from './logo.svg';
import './App.css';
import Hero1 from './compo/Hero1';
import Footer from './compo/Footer';
import Header from './compo/Header';


function App() {
  return (
    <div className="App">
      <Header/>
     <Hero1/>
     <Footer/>
    </div>
  );
}

export default App;
