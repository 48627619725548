import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-6 text-center">
        <h2 className="text-2xl font-bold mb-4">Meiyazhagan Movie</h2>   
        <div className="mt-6">
          <p className="text-md mb-2">Where to Watch:</p>
          <a
            href="https://snapmoviehd.com/download/meiyazhagan"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-400 hover:text-blue-600"
          >
            You can watch Meiyazhagan Full Movie
          </a>
        </div>

        <div className="mt-8 text-sm">
          <p>&copy; {new Date().getFullYear()} Meiyazhagan Movie. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
